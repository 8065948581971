// ************************************************************************************************************************************ //
//  HEADER
// ************************************************************************************************************************************ //
$(function() {

  $.ajaxSetup({
    headers : {
        'CsrfToken': $('meta[name="csrf-token"]').attr('content')
    }
  });

  // Form Busca
  $('body').on(
    'submit',
    '.form-busca',
    function(e) {
      e.preventDefault();
      window.location = '/busca/' + escape($(this).find('.busca').val());
    }
  );

  // request by phone
  // pedido-telefone.php
  //
  if ($('.request-by-phone').length >  0){
    var lastScrollTop = 0;
    var delta = 5;
    var $targetElement = $('.request-by-phone');
    var $navbar = $('.navbar-fixed-top');
    var navbarHeight = $('.request-by-phone').outerHeight();

    var scroll = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame || window.oRequestAnimationFrame || function(callback){ window.setTimeout(callback, 1000/60) }
    if (scroll){
      hasScrolled();
    }

    function hasScrolled() {
      var st = $(this).scrollTop();
      if (Math.abs(lastScrollTop - st) <= delta){
        scroll(hasScrolled);
        return false;
      }

      // If current position > last position AND scrolled past navbar...
      if (st > lastScrollTop && st > navbarHeight) {  // Scroll Down
        $targetElement.removeClass('nav-down');
        $navbar.removeClass('active-navbar-phone');
        $('body').removeClass('active')
      } else {  // Scroll Up
        // If did not scroll past the document (possible on mac)...
        if(st + $(window).height() < $(document).height()) {
          $targetElement.addClass('nav-down');
          $navbar.addClass('active-navbar-phone');
          $('body').addClass('active');
        }
      }

      lastScrollTop = st;
      scroll(hasScrolled);
    }
  }

  // menu
  $('.open-menu').click(function(e){
    e.preventDefault();

    var self          = $(this),
        menu          = $('#menu-mobile');

    if(self.hasClass('open')) {
      self.removeClass('open');
      menu.css('overflow', 'hidden').removeClass('open');
      $('.submenu').removeClass('open');
      $('#menu-mobile >form').show();
      $('#menu-mobile .has-submenu > a').show();
    }else{
      self.addClass('open');
      $('#menu-mobile >form').show();
      menu.addClass('open').css('overflow', 'auto');
    }
  });

  $('#menu-mobile .has-submenu > a').click(function(e){
    e.preventDefault();

    var self          = $(this),
        menu          = $('#menu-mobile'),
        submenu       = self.parent().find('.submenu');

    menu.css('overflow', 'hidden');
    submenu.addClass('open');
    self.hide();

    $('#menu-mobile >form').fadeOut();

  });

  $('#menu-mobile .has-submenu a.voltar').click(function(e){
    e.preventDefault();

    var self          = $(this),
        menu          = $('#menu-mobile'),
        submenu       = self.closest('.submenu');

    submenu.removeClass('open');
    menu.css('overflow', 'auto');
    $('#menu-mobile .has-submenu > a').show();

     $('#menu-mobile >form').fadeIn();

  });

  // logged
  var loggedTimer;
  $('#logedIn .logged').on('mouseenter mouseleave', function(e) {
    if(e.type == 'mouseenter') {
      clearTimeout(loggedTimer);
      $('#logedIn .logged').addClass('active');
    } else if(e.type == 'mouseleave') {
      loggedTimer = setTimeout(function() {
        $('#logedIn .logged').removeClass('active');
      }, 400)
    }
  });

  $('#aceitar-cookies').click(function(e){
    e.preventDefault();
    $('#cookie-message').remove();
    return false;
  });
});

